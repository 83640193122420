Kubrick.Homepage = {
  ANIMATION_SLIDE_DURATION: 2000,
  ANIMATION_SLIDE_WAIT: 10000,

  _options: false,
  _person_count: 0,
  _load_count: 0,
  _images: [],

  $PARENT: document.querySelector(".page-banner"),
  $DOM: document.querySelector(".page-banner #intro"),

  ////
  // Configuration
  ////
  configure: function(options) {
    Kubrick._log("Homepage: Configuring...", options);

    // Store options
    this._options = options;

    // Get other DOM
    this.$HEADSHOTS = this.$DOM.querySelector(".headshots");
    this.$GRAPHICS = this.$DOM.querySelector(".graphics");
    let first = this.$HEADSHOTS.querySelector("img");
    this._images.push(first);

    // Check if image is loaded, otherwise wait for image to load before starting others
    if (first.complete) {
      this._init();
    } else {
      first.addEventListener("load", this._init.bind(this));
    }
  },

  ////
  // Initiate
  ////
  _init: function() {
    Kubrick._log("Homepage: Initiating...");

    // Initiate loading
    try {
      var self = this;
      for (let i = 1; i < this._options.length; i++) {
        Kubrick._log(decodeURIComponent(this._options[i].url));

        var img = new Image();
        img.classList.add("headshot", "image", "fit");

        img.addEventListener("load", function(e) {
          Kubrick._log("Loaded...", e);

          // Append person
          let el = self.$HEADSHOTS.appendChild(this);
          el.style.display = "none";
          el.style.opacity = 0;
          self._images.push(el);

          // Check if it's ready to animate
          self._load_count++;
          if (self._load_count == self._options.length - 1) {
            setTimeout(
              self._showNextPerson.bind(self),
              self.ANIMATION_SLIDE_WAIT
            );
          }
        });
        img.src = decodeURIComponent(this._options[i].url);
      }
    } catch (e) {
      console.log("Error loading:", e);
    }
  },

  ////
  // Show Next Person
  ////
  _showNextPerson: function() {
    var current_person = this._images[this._person_count];
    if (++this._person_count == this._images.length) {
      this._person_count = 0;
    }
    var next_person = this._images[this._person_count];
    var self = this;
    anime({
      targets: current_person,
      opacity: [1, 0],
      easing: Kubrick.EASING.NORMAL,
      duration: this.ANIMATION_SLIDE_DURATION,
      complete: function() {
        current_person.style.display = "none";
        next_person.style.display = "block";
        anime({
          targets: next_person,
          opacity: [0, 1],
          easing: Kubrick.EASING.NORMAL,
          duration: self.ANIMATION_SLIDE_DURATION,
          complete: function() {
            // Animate
            setTimeout(
              self._showNextPerson.bind(self),
              self.ANIMATION_SLIDE_WAIT
            );
          }
        });
      }
    });
  }
};
